import { useCallback } from "react";
import { useEvent } from "react-use";
export type MessageMeta = { source: string; timestamp: number };

export const SIMPLE_MESSAGE_PUBLISHER_PREFIX = "publish_";

// adds prefix to scope these message events and make them easier to distinguish from other local storage events
const formatTopic = (topic: string, prefix = SIMPLE_MESSAGE_PUBLISHER_PREFIX) => {
  return prefix + topic;
};

export const useMessagePublisher = <T extends { [index: string]: any }>(
  topic: string,
  publishPrefix = SIMPLE_MESSAGE_PUBLISHER_PREFIX
) => {
  const publish = useCallback(
    (message: T) => {
      if (typeof window === "undefined") return;
      const finalMessage = {
        ...message,
        source: window.name,
        timestamp: Date.now(),
      };

      window.localStorage?.setItem(formatTopic(topic, publishPrefix), JSON.stringify(finalMessage));
    },
    [topic, publishPrefix]
  );

  return { publish };
};

export interface UseSubscribeToMessagesProps<T> {
  topic: string;
  callback: (message: T & MessageMeta) => void;
  publishPrefix?: string;
}

export const useMessageListener = <T extends { [index: string]: any }>({
  topic,
  callback,
  publishPrefix = SIMPLE_MESSAGE_PUBLISHER_PREFIX,
}: UseSubscribeToMessagesProps<T>) => {
  const subscriber = useCallback(
    (event: StorageEvent) => {
      if (topic && event.key === formatTopic(topic, publishPrefix) && event.newValue) {
        callback(JSON.parse(event.newValue));
      }
    },
    [topic, callback, publishPrefix]
  );

  useEvent("storage", subscriber);
};
