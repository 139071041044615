import { User } from "@auth0/auth0-react";
import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { getGuestUserId } from "@lib/flags/flags.client";

export const useIdentifySentryUser = ({ user }: { user: User | null }) => {
  useEffect(() => {
    const guestUserId = getGuestUserId();
    if (!user?.sub && !guestUserId) {
      return Sentry.setUser(null);
    }

    Sentry.setUser({
      id: user?.sub || guestUserId,
      email: user?.email,
    });
  }, [user?.sub, user?.email]);
};
