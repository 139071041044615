import {
  MessageMeta,
  useMessageListener,
  useMessagePublisher,
} from "@lib/simpleMessagePublisher/useMessagePublisher";
import { useCallback, useEffect } from "react";

export type AuthUpdate = {
  isAuthenticated: boolean;
};

export interface UseAuthSyncProps {
  handleSignin: () => void;
  handleLogout: () => void;
  isAuthenticatedInApp: boolean;
  isAuthLoading: boolean;
}

export const AUTH_TOPIC = "auth";

export const useAuthSync = ({
  handleLogout,
  handleSignin,
  isAuthenticatedInApp,
  isAuthLoading,
}: UseAuthSyncProps) => {
  const { publish } = useMessagePublisher(AUTH_TOPIC);

  // publisher
  useEffect(() => {
    if (isAuthLoading) return;
    publish({
      isAuthenticated: isAuthenticatedInApp,
    });
  }, [isAuthenticatedInApp, publish, isAuthLoading]);

  // listener
  const handleAuthUpdate = useCallback(
    (message: AuthUpdate & MessageMeta) => {
      console.log("auth-sync", {
        message,
        isAuthenticatedInThisApp: isAuthenticatedInApp,
      });

      if (message.isAuthenticated && !isAuthenticatedInApp) {
        console.log("auth-sync - logging in");
        handleSignin();
      } else if (!message.isAuthenticated && isAuthenticatedInApp) {
        console.log("auth-sync - logging out");
        handleLogout();
      }
    },
    [isAuthenticatedInApp, handleSignin, handleLogout]
  );

  useMessageListener<AuthUpdate>({
    topic: AUTH_TOPIC,
    callback: handleAuthUpdate,
  });
};
