"use client";

import { useDatadogPageViews } from "@app/hooks/useDatadogPageViews";
import { isTrackingDisabled } from "@app/hooks/useThirdParty";
import { useCheckOnboardingStatus } from "@providers/CheckOnboardingWrapper/useCheckOnboardingStatus";
import { Toaster } from "@sourceful/design-system-v3";
import dynamic from "next/dynamic";
import { usePathname, useRouter } from "next/navigation";
import { FunctionComponent, createContext, useContext } from "react";
import { useDatadog } from "../../../lib/hooks/useDatadog";
import { i18n } from "../../../next-i18next.config";
import IntercomButton from "../../components/common/intercom/IntercomButton";
import {
  useLeadGenExitDialog,
  useLeadGenExitDialogType,
} from "../../components/lead-gen/useLeadGenExitDialog";
import { useAuthentication } from "../AuthenticationProvider/AuthenticationProvider";

// Polyfills can go here (or in specific components), should be part of main bundle then
import "@ungap/global-this";
import env from "../../../lib/utils/env";
import { GoogleTagManager } from "@next/third-parties/google";

const LeadGenExitDialog = dynamic(() => import("../../components/lead-gen/LeadGenExitDialog"), {
  ssr: false,
});

const GTM_CODE = env("GTM_CODE");

export interface PageProviderInjectedProps {
  draftMode: boolean;
  locale: string;
  showExitDialog: useLeadGenExitDialogType | null;
  setShowExitDialog: (value: useLeadGenExitDialogType) => void;
}

export interface PageProviderProps {
  draftMode: boolean;
  children?: React.ReactNode;
  leadGenExitDialogContainer?: HTMLElement | null;
}

const PageContext = createContext({} as PageProviderInjectedProps);

const usePageContext = () => useContext(PageContext);

const PageProvider: FunctionComponent<PageProviderProps> = ({
  draftMode = false,
  children,
  leadGenExitDialogContainer,
}) => {
  const { isLoading, loginCount, user, isAuthenticated, metadata, getAccessTokenSilently } =
    useAuthentication();

  const router = useRouter();

  useDatadog();
  useDatadogPageViews();

  useCheckOnboardingStatus({
    router,
    revalidate: false,
    authProps: {
      isLoading,
      loginCount,
      user,
      isAuthenticated,
      metadata,
      getAccessTokenSilently,
    },
  });

  const pathname = usePathname();

  const {
    value: showExitDialog,
    set: setShowExitDialog,
    isLeadGenPage,
  } = useLeadGenExitDialog(pathname);

  return (
    <PageContext.Provider
      value={{
        draftMode,
        locale: i18n.defaultLocale,
        showExitDialog,
        setShowExitDialog,
      }}
    >
      {!isTrackingDisabled() && GTM_CODE && <GoogleTagManager gtmId={GTM_CODE} />}
      {children}
      {/* NOTE: Quick fix - we have issues with nesting now z-indexes come into play.
      So the close button popover doesn't inherit this because its rendered in the body */}
      <div className="relative z-30">
        <Toaster swipeDirection="right" />
      </div>
      <IntercomButton />

      {showExitDialog && showExitDialog.show && !isLeadGenPage && (
        <LeadGenExitDialog
          container={leadGenExitDialogContainer}
          onContinue={() => {
            setShowExitDialog({ show: false, seen: true });
          }}
          onClose={() => {
            setShowExitDialog({ show: false, seen: true });
          }}
        />
      )}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider, usePageContext };
