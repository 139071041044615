/*
  IMPORTANT! This file needs to be compatible with both pages and app router - do NOT use any functionality specific to either router e.g any providers etc.
*/

import { GetTokenSilentlyOptions, User } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getOnboardingStatus, OnboardingStatus } from "./helpers";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { NextRouter } from "next/router";
export enum OnboardingUrl {
  RegisterEmail = "/app/signup/email",
  RegisterDetails = "/app/signup/details",
  RegisterOrganisation = "/app/signup/organisation",
}

export const AUTH0_USERNAME_PASSWORD_PREFIX = "auth0|";

export const isUsernamePasswordLogin = (userId: string = "") =>
  userId.startsWith(AUTH0_USERNAME_PASSWORD_PREFIX);

export const getUserName = (user: User | undefined) => {
  if (isUsernamePasswordLogin(user?.sub)) {
    return user?.given_name || "";
  }

  // if social login, try as many names as possible as we need a name and auth0 limitations mean the user can't submit one
  return user?.given_name || user?.nickname || user?.name || "";
};

interface UseCheckOnboardingStatusProps {
  router: NextRouter | AppRouterInstance; // handles both pages router and app router
  onOnboardingComplete?: () => Promise<void>;
  revalidate: boolean;
  authProps: {
    isLoading: boolean;
    user: User | undefined;
    isAuthenticated: boolean;
    getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
    loginCount: number | undefined;
    metadata:
      | {
          [index: string]: any;
          phone_number?: string | undefined;
        }
      | undefined;
  };
}

export const useCheckOnboardingStatus = ({
  onOnboardingComplete,
  revalidate = false,
  router,
  authProps: { isLoading, user, isAuthenticated, getAccessTokenSilently, loginCount, metadata },
}: UseCheckOnboardingStatusProps) => {
  const [isRevalidating, setIsRevalidating] = useState(false);

  const username = getUserName(user);
  const email = user?.email || username;

  const emailVerified = user?.email_verified || false;
  const phoneNumber = metadata?.phone_number || ""; // phone number is stored on user metadata as the actual phone number field on the main user objec tis reserved for SMS connections and have extra validations: https://community.auth0.com/t/why-can-we-not-set-a-users-phone-number/48574

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      console.log("Checking onboarding status", {
        isLoading,
        isAuthenticated,
        email_verified: emailVerified,
        user_name: username,
        loginCount,
        phoneNumber,
      });

      if (isLoading) return;
      if (!isAuthenticated) return;
      if (loginCount === undefined) return;

      const onboardingStatus = getOnboardingStatus({
        emailVerified,
        phoneNumber,
        name: username,
        loginCount,
        email,
      });

      if (onboardingStatus === OnboardingStatus.EMAIL_NOT_VERIFIED) {
        router.replace(OnboardingUrl.RegisterEmail);
      } else if (onboardingStatus === OnboardingStatus.MISSING_DETAILS) {
        // we only want new users without phone numbers to be routed to onboarding page
        router.replace(OnboardingUrl.RegisterDetails);
      } else if (onOnboardingComplete) {
        await onOnboardingComplete();
      }
    };

    checkOnboardingStatus();
  }, [isAuthenticated, isLoading, emailVerified, username, phoneNumber, loginCount]);

  useEffect(() => {
    const recheckOnboarding = async () => {
      if (revalidate && isAuthenticated && !isRevalidating) {
        setIsRevalidating(true);
        console.log("Rechecking onboarding status");
        await getAccessTokenSilently({ cacheMode: "off" }).catch(error => {
          setIsRevalidating(false);
          // ignore silent auth error in safari when auth0 domain does not match (e.g. in local dev):
          // https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari
          if (error?.error === "login_required") {
            return console.log("User not loggged in - this error can be safely ignored");
          }

          throw error;
        });
      }

      setIsRevalidating(false);
    };

    // add visibility change handler on document for better safari support
    document.addEventListener("visibilitychange", recheckOnboarding);
    // account for multiple windows being open at the same time
    window.addEventListener("focus", recheckOnboarding);

    return () => {
      document.removeEventListener("visibilitychange", recheckOnboarding);
      window.removeEventListener("focus", recheckOnboarding);
    };
  }, [revalidate, isAuthenticated]);

  return { isRevalidating };
};
