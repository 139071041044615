import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import env from "../../../lib/utils/env";

const COOKIE_LEAD_GEN_EXIT_DIALOG = "lead-gen-exit-dialog";
const COOKIE_DOMAIN = env("ROOT_HOSTNAME") ?? "localhost";

export type useLeadGenExitDialogType = {
  show: boolean;
  seen: boolean;
};

export function useLeadGenExitDialog(pathname: string | null) {
  const set = useCallback((value: useLeadGenExitDialogType) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    Cookies.set(COOKIE_LEAD_GEN_EXIT_DIALOG, JSON.stringify(value), {
      expires: date,
      path: "/",
      domain: COOKIE_DOMAIN,
    });

    setValue(value);
  }, []);

  const get = useCallback((): useLeadGenExitDialogType | null => {
    const cookie = Cookies.get(COOKIE_LEAD_GEN_EXIT_DIALOG);
    return cookie ? (JSON.parse(cookie) as useLeadGenExitDialogType) : null;
  }, []);

  const [value, setValue] = useState<useLeadGenExitDialogType | null>(get());

  return {
    set,
    value,
    isLeadGenPage: pathname?.includes("lead-gen"),
  };
}
