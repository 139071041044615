import {
  default as defaultRules,
  Permissions,
  RBACAction,
  RBACRules,
  ProductCloudRole,
  PRODUCT_CLOUD_DEFAULT_ROLE,
  SOURCEFUL_ROLES,
} from "./rbac-rules";

export const checkPermissions = <PermissionParams extends unknown>(
  roleList: ProductCloudRole[],
  action: RBACAction,
  data: PermissionParams | null,
  rules: RBACRules = defaultRules
) => {
  const permissions: Permissions<PermissionParams> = {
    static: [],
    dynamic: [],
  };

  // get all the permissions for each role the user has
  for (let role of roleList) {
    if (!rules[role]) {
      console.warn(`Role '${role}' not defined in RBAC rules`);
      continue;
    }

    permissions.static.push(...rules[role].static);
    permissions.dynamic.push(...rules[role].dynamic);
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  for (let dynamicPermission of permissions.dynamic) {
    const isRelevant = dynamicPermission.action === action;
    if (!isRelevant || !data) continue;

    const isAllowed = dynamicPermission.validate(data);
    if (isAllowed) {
      return true;
    }
  }

  return false;
};

interface GetProductCloudRolesArgs {
  roles: string[];
  userOrgId: string;
  sourcefulOrgId: string;
  sourcefulRoles?: ProductCloudRole[];
  onSourcefulRoleAttributionError?: () => void;
}
export const getProductCloudRoles = ({
  roles,
  userOrgId,
  sourcefulOrgId,
  sourcefulRoles = SOURCEFUL_ROLES,
  onSourcefulRoleAttributionError = () => {
    console.error(`Role attribution error`);
  },
}: GetProductCloudRolesArgs): ProductCloudRole[] => {
  const productCloudRoles = new Set(Object.values(ProductCloudRole));
  const _sourcefulRoles = new Set(sourcefulRoles);

  const userRoles = roles.filter(role => {
    const isProductCloudRole = productCloudRoles.has(role as ProductCloudRole);
    const isSourcefulRole = _sourcefulRoles.has(role as ProductCloudRole);

    // filter out sourceful roles if user does not belong to sourceful
    if (isSourcefulRole) {
      const isSourcefulUser = userOrgId === sourcefulOrgId;

      if (!isSourcefulUser) {
        onSourcefulRoleAttributionError();
      }

      return isProductCloudRole && isSourcefulUser;
    }

    return isProductCloudRole;
  });

  if (userRoles.length < 1) {
    return [PRODUCT_CLOUD_DEFAULT_ROLE];
  }

  return userRoles as ProductCloudRole[];
};

export const getDefaultRole = (roles: ProductCloudRole[], rules: RBACRules = defaultRules) => {
  // default to the role with the most permissions
  // TODO: add check against not sourceful users having admin role
  let strongestRole = roles[0];

  roles.forEach(role => {
    const permissions = rules[role].static;
    const strongesRolePermissions = rules[strongestRole].static;

    if (permissions.length > strongesRolePermissions.length) {
      strongestRole = role;
    }
  });

  return strongestRole || PRODUCT_CLOUD_DEFAULT_ROLE;
};
