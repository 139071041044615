"use client";

import { IconAlertExclamationFill, useToast } from "@sourceful/design-system-v3";
import { FunctionComponent, createContext, useCallback, useContext, useState } from "react";

export enum ErrorStatus {
  "fatal",
  "warning",
  "local",
}

export interface ErrorProviderInjectedProps {
  errors: IErrors;
  pushError: (id: string, error: Error, log: boolean) => void;
  reportError: (error: Error) => void;
  clearErrorByIdAndMessage: (id: string, message: string) => void;
  clearErrorById: (id: string) => void;
}

export interface Error {
  status: ErrorStatus;
  message: string;
  stack: any;
  toastId?: string;
}

export interface ErrorWithKey extends Error {
  key: string;
}

export interface IErrors {
  [key: string]: Error[];
}

export interface ErrorProviderProps {
  errors?: IErrors;
  children?: React.ReactNode;
}

const ErrorContext = createContext({} as ErrorProviderInjectedProps);

const useErrorContext = () => useContext(ErrorContext);

const ErrorProvider: FunctionComponent<ErrorProviderProps> = ({
  children,
  errors: injectedErrors = {},
}) => {
  const [errors, setErrors] = useState<IErrors>(injectedErrors);

  const { toast, dismiss } = useToast();

  const pushError = useCallback(
    (id: string, error: Error, log: boolean = true) => {
      const createdToast = toast({
        title: "Error",
        description: error.message,
        variant: "error",
        duration: 99999999,
        figure: <IconAlertExclamationFill className="size-32" />,
      });

      error.toastId = createdToast.id;

      setErrors((currentErrors: IErrors) => {
        if (currentErrors[id]) {
          return {
            ...currentErrors,
            [id]: [...currentErrors[id], error],
          };
        }

        return {
          ...currentErrors,
          [id]: [error],
        };
      });

      if (log) {
        console.error(`Error in ${id} section`, error.message, error.stack);
      }
    },
    [toast]
  );

  const reportError = useCallback((error: Error) => {
    console.error(error.message, error.stack);
  }, []);

  const clearErrorByIdAndMessage = (id: string, message: string) => {
    const newErrors = Object.keys(errors).reduce((obj: IErrors, key: string): IErrors => {
      obj[key] = errors[key].reduce((acc: Error[], item: Error) => {
        if (key !== id) {
          acc.push(item);
          return acc;
        }

        if (item.message === message && item.toastId) {
          dismiss(item.toastId);
        }

        if (item.message !== message) acc.push(item);

        return acc;
      }, []);

      return obj;
    }, {});

    setErrors(newErrors);
  };

  const clearErrorById = useCallback(
    (id: string) => {
      const newErrors = Object.keys(errors).reduce((obj: IErrors, key: string): IErrors => {
        obj[key] = errors[key].reduce((acc: Error[], item: Error) => {
          if (key === id && item.toastId) {
            dismiss(item.toastId);
          }

          if (key !== id) {
            acc.push(item);
            return acc;
          }

          return acc;
        }, []);

        return obj;
      }, {});

      setErrors(newErrors);
    },
    [dismiss, errors]
  );

  return (
    <ErrorContext.Provider
      value={{
        errors,
        pushError,
        reportError,
        clearErrorByIdAndMessage,
        clearErrorById,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export { ErrorContext, ErrorProvider, useErrorContext };
