import { useRef, useState } from "react";
import { useIntercomInit } from "./useThirdParty";

export const useIntercomInitButton = () => {
  const { initialiseIntercom } = useIntercomInit();
  const hasBegunFetchingScripts = useRef(false);
  const [show, setShow] = useState(true);

  const props = {
    onMouseOver: () => {
      if (hasBegunFetchingScripts.current) return;
      initialiseIntercom();
      hasBegunFetchingScripts.current = true;
    },
    onFocus: () => {
      if (hasBegunFetchingScripts.current) return;
      initialiseIntercom();
      hasBegunFetchingScripts.current = true;
    },
    onClick: async () => {
      const interval = setInterval(() => {
        if (typeof (global.window as any).Intercom === "function") {
          clearInterval(interval);
          (global.window as any).Intercom("show");
          setTimeout(() => {
            setShow(false);
          }, 1e3);
        }
      }, 100);
    },
  };

  return { props, show };
};
