"use client";

import { useIntercomInitButton } from "@app/hooks/useIntercomInitButton";
import { baseButtonVariants, cn } from "@sourceful/design-system-v3";

export default function IntercomButton() {
  const { show, props: intercomButtonProps } = useIntercomInitButton();

  if (!show) return null;

  return (
    <button
      className={cn(
        baseButtonVariants({ size: "xs", layout: "iconOnly" }),
        `fixed bottom-20 left-20 
        bg-brand-blue 
        w-48 h-48 flex items-center justify-center
        rounded-pill cursor-pointer
        transition-all duration-200
        hover:scale-110`
      )}
      {...intercomButtonProps}
      aria-label="Open Intercom Messenger"
      aria-live="polite"
    >
      <img
        src="https://downloads.intercomcdn.com/i/o/470314/d1686db746ea4d2c1732e9ae/c28baf52e09fd4d9590172ad44d8de05.png"
        alt="Intercom activation icon"
        className="w-24 h-24"
      />
    </button>
  );
}
