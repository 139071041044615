export const MAX_LOGIN_COUNT_FOR_PHONE_CAPTURE = 1; // we don't want to ask existing users to add phone number, just new ones.

export const requiresPhoneNumberCapture = (phoneNumber: string, loginCount: number) => {
  return !phoneNumber && loginCount <= MAX_LOGIN_COUNT_FOR_PHONE_CAPTURE;
};

export interface RequiresOnboardingArgs {
  phoneNumber: string;
  loginCount: number;
  name: string;
  emailVerified: boolean;
  email: string;
}

export enum OnboardingStatus {
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  MISSING_DETAILS = "MISSING_DETAILS",
  ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE",
}

export const getOnboardingStatus = ({
  emailVerified,
  name,
  phoneNumber,
  loginCount,
  email,
}: RequiresOnboardingArgs): OnboardingStatus => {
  const sourcefulDomain = "sourceful.com";
  const isSourcefulEmail = email.toLowerCase().endsWith(`@${sourcefulDomain}`);

  if (isSourcefulEmail) {
    return OnboardingStatus.ONBOARDING_COMPLETE;
  }

  if (!emailVerified) {
    return OnboardingStatus.EMAIL_NOT_VERIFIED;
  } else if (!name || requiresPhoneNumberCapture(phoneNumber, loginCount)) {
    return OnboardingStatus.MISSING_DETAILS;
  } else {
    return OnboardingStatus.ONBOARDING_COMPLETE;
  }
};
