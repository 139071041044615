export type AgencyAction =
  | "agency/skus:view"
  | "agency/skus:create"
  | "agency/skus:edit"
  | "agency/presets:view"
  | "agency/presets:create"
  | "agency/quotes:view"
  | "agency/quotes:update"
  | "agency/quotes:delete"
  | "agency/quotes:submit"
  | "agency/orders:view"
  | "agency/orders:update"
  | "agency/order/artwork-creation:assign"
  | "agency/order/artwork-creation:approve" // upload and approve
  | "agency/order/artwork-approval:assign"
  | "agency/order/artwork-approval:approve"
  | "agency/order:delete"
  | "agency/order/fsc-check:assign"
  | "agency/order/fsc-check:approve"
  | "agency/emails:view"
  | "agency/flags:update"
  | "agency/engine-settings:view"
  | "agency/engine-settings:update"
  | "agency/basket:transfer"
  | "agency/configuration:duplicate"
  | "agency/templates:create"
  | "agency/debug:view"
  | "agency/forms:view"
  | "agency/spring:update";

export type ClientAction = "quotes:view" | "quotes:accept" | "orders:view";

export type RBACAction = AgencyAction | ClientAction;

export enum ProductCloudRole {
  CLIENT_ADMIN = "client_admin",
  SOURCEFUL_ADMIN = "sourceful_product_cloud_admin",
  SOURCEFUL_ARTWORK_CREATOR = "sourceful_product_cloud_artwork_creator",
  SUPER_ADMIN = "admin", // dev super user
  PUBLIC = "public",
}

export const SOURCEFUL_ROLES: ProductCloudRole[] = [
  ProductCloudRole.SOURCEFUL_ADMIN,
  ProductCloudRole.SUPER_ADMIN,
];

export const PRODUCT_CLOUD_DEFAULT_ROLE = ProductCloudRole.CLIENT_ADMIN;

/* 
    There are two types of permissions. 
    1. Static permissions always apply if the user has a given role
    2. Dynamic permissions run a function to validate certain assertions to decide whether access is allowed
*/

export interface DynamicPermission<PermissionParams> {
  action: RBACAction;
  validate: (params: PermissionParams) => boolean;
}
export interface Permissions<PermissionParams> {
  static: RBACAction[];
  dynamic: DynamicPermission<PermissionParams>[];
}

export interface RBACRules {
  [name: string]: Permissions<any>;
}

const sharedStaticAdminPermissions: RBACAction[] = [
  "agency/skus:view",
  "agency/skus:create",
  "agency/skus:edit",
  "agency/presets:view",
  "agency/presets:create",
  "agency/orders:view",
  "agency/orders:update",
  "agency/order/artwork-creation:assign",
  "agency/order/artwork-approval:assign",
  "agency/order/fsc-check:assign",
  "agency/order:delete",
  "agency/quotes:view",
  "agency/quotes:update",
  "agency/quotes:delete",
  "agency/emails:view",
  "agency/flags:update",
  "quotes:view",
  "quotes:accept",
  "orders:view",
  "agency/engine-settings:view",
  "agency/engine-settings:update",
  "agency/basket:transfer",
  "agency/configuration:duplicate",
  "agency/templates:create",
  "agency/debug:view",
  "agency/forms:view",
  "agency/spring:update",
];

const sharedDynamicAdminPermissions: DynamicPermission<any>[] = [
  {
    action: "agency/order/fsc-check:approve",
    validate: ({ userId, assignee_uuid }) => userId && assignee_uuid && userId === assignee_uuid,
  },
  {
    action: "agency/order/artwork-creation:approve",
    validate: ({ userId, assignee_uuid }) => userId && assignee_uuid && userId === assignee_uuid,
  },
  {
    action: "agency/order/artwork-approval:approve",
    validate: ({ userId, assignee_uuid }) => userId && assignee_uuid && userId === assignee_uuid,
  },
];

const sharedPublicPermissions: RBACAction[] = ["quotes:view", "quotes:accept", "orders:view"];

const rules: RBACRules = {
  [ProductCloudRole.SUPER_ADMIN]: {
    static: [...sharedStaticAdminPermissions],
    dynamic: [...sharedDynamicAdminPermissions],
  },
  [ProductCloudRole.SOURCEFUL_ADMIN]: {
    static: [...sharedStaticAdminPermissions],
    dynamic: [...sharedDynamicAdminPermissions],
  },
  [ProductCloudRole.CLIENT_ADMIN]: {
    static: [...sharedPublicPermissions],
    dynamic: [],
  },
  [ProductCloudRole.SOURCEFUL_ARTWORK_CREATOR]: {
    static: [...sharedPublicPermissions, "agency/orders:view"],
    dynamic: [
      {
        action: "agency/order/artwork-creation:approve",
        validate: ({ userId, assignee_uuid }) => userId === assignee_uuid,
      },
    ],
  },
};

export default rules;
